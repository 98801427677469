<template>
  <v-main id="home">
    <!-- <router-view /> -->
    <router-view ref="component" :parentComponent="parentComponent"/>
  </v-main>
</template>

<script>
  export default {
    name: 'CoreView',
    props: ['parentComponent'],
    methods: {
      selectLanguage (value) {
        console.log(value, 'selectLanguage')
        var child = this.$refs.component
        console.log(child, 'child')
        child.selectLanguage(value)
      },
    }
  }
</script>
